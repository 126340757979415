







































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { AppModule, Work } from '@/store/app'
import { ApiHelper } from '@/ApiHelper';
import { dateToFormatString } from '@/dateToFormatString'

import WorksComponent from '@/components/WorksComponent.vue'
import BaseButton from '@/components/BaseButton.vue'
@Component({
  components: {
    WorksComponent,
    BaseButton
  },
  head: {
    title: {
      inner: 'インフォメーション'
    },
    meta() {
      return [
        { property: 'og:url', content: 'https://www.iekichi.com/', id:'ogurl' },
        { property: 'og:title', content: 'Architectural Design 家吉｜住宅・店舗の設計、デザイン', id:'ogtitle' },
        { property: 'og:type', content: 'website', id: 'ogtype' },
        { property: 'og:description', content: '住まいをつくる、お店をつくる、農作物をつくる。様々な「つくる人たち」と、ゆるやかにつながり支えあいながら、建築を通して「心地よい暮らし」を提案しています。｜一級建築設計事務所 家吉建築デザイン', id: 'ogdescription'},
        { property: 'og:site_name', content: 'Architectural Design 家吉｜住宅・店舗の設計、デザイン', id: 'ogsitename' },
        { name: 'twitter:card', content: 'summary_large_image', id:'twittercard' },
      ];
    },
  },
})
export default class Informations extends Vue {
  private title='すべて';
  private titles: { [key: string]: string } = {
    'archi': '建築',
    'agri': '農業',
    'tsukinomori': '槻の森',
  }

  private totalPages = 1;
  private get currentPage(){
    return parseInt(this.$route.query.page as string) || 1
  }

  private removeBr(str: string){
    return str.replace('<br>', ' ');
  }

  private informations = [];
  mounted() {
    this.getData();
  }

  @Watch('$route')
  private async getData(){
    this.title = this.titles[this.$route.params.slug] || 'すべて';
    let path = `posts?_embed&per_page=10`;
    if(this.$route.query.page){
      path = `${path}&page=${this.$route.query.page}`;
    }
    const slug = this.$route.params.slug || 'info';
    await ApiHelper.callApi(`categories?slug=${slug}`, 'get', {}, res => {
      path = `${path}&categories=${res.data[0].id}`;
    });
    ApiHelper.callApi(path, 'get', {}, res => {
      this.totalPages = res.headers["x-wp-totalpages"];
      this.informations = res.data.map((v: any)=>{ return {...v, dateString: dateToFormatString(new Date(v.date),'%YYYY%年%M%月%D%日')} });
    });
  }

}
